


export const blogs = [
    {
      id: 1,
      title: 'The Future of AI in Tech',
      content: 'Artificial Intelligence (AI) is transforming industries across the world...',
      author: 'Bright',
      date: 'October 10, 2024',
      category: 'Artificial Intelligence',
      image: 'https://images.unsplash.com/photo-1518770660439-4636190af475', // AI
    },
    {
      id: 2,
      title: 'Why Startups Should Focus on Sustainability',
      content: 'Sustainability is no longer just a buzzword...',
      author: 'Jane Smith',
      date: 'September 25, 2024',
      category: 'Sustainability',
      image: 'https://images.unsplash.com/photo-1542831371-d531d36971e6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDI2fHxzdXN0YWluYWJpbGl0eXxlbnwwfHx8fDE2NjQzNzc3MTI&ixlib=rb-1.2.1&q=80&w=1080'
    },
    {
      id: 3,
      title: 'Cloud Computing: The Backbone of Modern Infrastructure',
      content: 'Cloud computing has become essential in managing data and infrastructure...',
      author: 'Alex Brown',
      date: 'August 30, 2024',
      category: 'Cloud Computing',
      image: 'https://images.unsplash.com/photo-1484417894907-623942c8ee29', // Cloud Computing
    },
    {
      id: 4,
      title: '5G Networks: What’s Next?',
      content: '5G networks promise faster speeds and improved connectivity...',
      author: 'Lisa Ray',
      date: 'July 15, 2024',
      category: 'Telecommunications',
      image: 'https://images.unsplash.com/photo-1503676260728-1c00da094a0b', // 5G Networks
    },
    // {
    //   id: 5,
    //   title: 'Data Security in a Digital World',
    //   content: 'Protecting data has never been more important...',
    //   author: 'Mike Green',
    //   date: 'June 18, 2024',
    //   category: 'Cybersecurity',
    //   image: 'https://images.unsplash.com/photo-1620740746890-91c809475451?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDR8fGRhdGF8ZW58MHx8fHwxNjg0MzY4NTE0&ixlib=rb-1.2.1&q=80&w=1080'
    // },
    // {
    //   id: 6,
    //   title: 'Quantum Computing: The Next Tech Revolution',
    //   content: 'Quantum computing is set to revolutionize the tech industry...',
    //   author: 'Sarah Lee',
    //   date: 'May 5, 2024',
    //   category: 'Quantum Computing',
    //   image: 'https://images.unsplash.com/photo-1581349481589-ec8db43415e2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fHF1YW50dW18ZW58MHx8fHwxNjg0MzY4NTE1&ixlib=rb-1.2.1&q=80&w=1080'
    // },
    // {
    //   id: 7,
    //   title: 'The Rise of Edge Computing',
    //   content: 'Edge computing is gaining popularity as more devices become connected...',
    //   author: 'Tom White',
    //   date: 'April 12, 2024',
    //   category: 'Edge Computing',
    //   image: 'https://www.pexels.com/photo/circuit-board-connection-computer-163100/', // Edge Computing
    // },
    // {
    //   id: 8,
    //   title: 'Blockchain Beyond Cryptocurrency',
    //   content: 'Blockchain technology is most commonly associated with cryptocurrencies...',
    //   author: 'David Black',
    //   date: 'March 29, 2024',
    //   category: 'Blockchain',
    //   image: 'https://www.pexels.com/photo/blockchain-network-computer-connection-270004/', // Blockchain
    // },
    // {
    //   id: 9,
    //   title: 'The Role of AI in Healthcare',
    //   content: 'AI is changing the healthcare industry in profound ways...',
    //   author: 'Emily Clark',
    //   date: 'February 14, 2024',
    //   category: 'Healthcare Technology',
    //   image: 'https://www.freepik.com/free-vector/ai-healthcare-concept-illustration_168283701.htm#fromView=search&page=1&position=0&uuid=2addc03f-63db-4194-b23e-80bbc7724635', // AI in Healthcare
    // },
    {
      id: 10,
      title: 'Autonomous Vehicles: A Glimpse Into the Future',
      content: 'The development of autonomous vehicles (AVs) has the potential to revolutionize transportation...',
      author: 'Robert Moore',
      date: 'January 22, 2024',
      category: 'Autonomous Vehicles',
      image: 'https://images.unsplash.com/photo-1525609004556-c46c7d6cf023', // Autonomous Vehicles
    },
  ];
  
  export default blogs;
  

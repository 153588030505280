import React from 'react';
import './quotation.css'; // Import custom styles
import { Button } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const quotations = [
  {
    id: 1,
    title: 'Mobile App Development',
    description: 'Get a fully customized mobile app tailored to your business needs.',
    priceRange: '$500 - $20,000',
  },
  {
    id: 2,
    title: 'Website Design & Development',
    description: 'Beautiful, responsive websites that drive conversions.',
    priceRange: '$400 - $15,000',
  },
  {
    id: 3,
    title: 'Data Analytics & Reporting',
    description: 'Leverage data for better decision making with analytics solutions.',
    priceRange: '$700 - $10,000',
  },
  {
    id: 4,
    title: 'Point of Sale (POS) System',
    description: 'Seamless POS systems to streamline sales and inventory management.',
    priceRange: '$200 - $1000',
  },
  {
    id: 5,
    title: 'Cloud Solutions',
    description: 'Scalable cloud computing services for your business.',
    priceRange: '$4000 - $12,000',
  },
  {
    id: 6,
    title: 'Custom Software Development',
    description: 'Tailor-made software solutions for specific business needs.',
    priceRange: '$1000 - $15,000',
  },
  {
    id: 7,
    title: 'E-commerce Solutions',
    description: 'Complete e-commerce platforms to scale your business online.',
    priceRange: '$600 - $10,000',
  },
  {
    id: 8,
    title: 'Cybersecurity Solutions',
    description: 'Protect your digital assets with advanced security solutions.',
    priceRange: '$2000 - $10,000',
  },
  {
    id: 9,
    title: 'IT Consulting',
    description: 'Strategic IT consulting services to enhance your business operations.',
    priceRange: '$800 - $7000',
  },
  {
    id: 10,
    title: 'AI & Machine Learning',
    description: 'Automate and innovate using AI and machine learning technologies.',
    priceRange: '$2000 - $20,000',
  },
];

const Quotation = () => {
  return (
    <div className="quotation-container">
      <h1>Our Services & Quotations</h1>
      <div className="quotation-grid">
        {quotations.map((quote) => (
          <div key={quote.id} className="quote-card">
            <div className="quote-header">
              <h2>{quote.title}</h2>
              <Button variant="outlined" startIcon={<InfoIcon />}>
                View More
              </Button>
            </div>
            <p>{quote.description}</p>
            <p><strong>Price Range: </strong>{quote.priceRange}</p>
          </div>
        ))}
      </div>
    </div>
  );
};


export default Quotation;

import React, { useState } from "react";
import styles from './Footer.module.css';
import fb from '../../assets/home/facebook.png';
import tiktok from '../../assets/home/tiktok.png';
import insta from '../../assets/home/insta.png';
import twitter from '../../assets/home/twitter.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const servicesData = [
    { name: 'Software Development', number: '+254799041239', color: '#fefefe' },
    { name: 'IT Consultancy', number: '+254799041239', color: '#fefefe' },
    { name: 'Cloud Solutions', number: '+254799041239', color: '#fefefe' },
    { name: 'Mobile App Development', number: '+254799041239', color: '#fefefe' },
    { name: 'Web Development', number: '+254799041239', color: '#fefefe' },
    { name: 'Cybersecurity Solutions', number: '+254799041239', color: '#fefefe' },
    { name: 'Data Analytics', number: '+254799041239', color: '#fefefe' },
    { name: 'E-commerce Solutions', number: '+254799041239', color: '#fefefe' },
    { name: 'Digital Marketing', number: '+254799041239', color: '#fefefe' },
];

const Footer = () => {
    const [openService, setOpenService] = useState(null);

    const toggleService = (index) => {
        if (openService === index) {
            setOpenService(null);
        } else {
            setOpenService(index);
        }
    };

    return (
        <>
            <div style={{ backgroundColor: '#285a77', paddingTop: '89px', paddingBottom: '90px' }}>
                <div className="container">
                    <div className="row">
                        <div className={`col-lg-3 col-sm-6 ${styles.contain}`}>
                            <p className={styles.logo}>Meyani Tech</p>
                            <p className={styles.text}>
                                Meyani Tech is dedicated to delivering innovative software solutions that drive business growth and efficiency.
                            </p>
                            <p className={styles.text}>
                                Our commitment to excellence ensures that we provide tailored technology services that meet the unique needs of our clients.
                            </p>
                            <p className={styles.text}>
                                From cutting-edge apps to robust IT support, we empower businesses to thrive in a digital world.
                            </p>
                            <div className={styles.social_logo}>
                                <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                                    <img src={fb} alt="Facebook" className={styles.img} />
                                </a>
                                <a href="https://vm.tiktok.com/" target="_blank" rel="noopener noreferrer">
                                    <img src={tiktok} alt="TikTok" className={styles.img} />
                                </a>
                                <a href="https://www.instagram.com/meyanihub/profilecard/?igsh=amRqM3h3dDNsa2c=" target="_blank" rel="noopener noreferrer">
                                    <img src={insta} alt="Instagram" className={styles.img} />
                                </a>
                                <a href="https://x.com/MeyaniHub" target="_blank" rel="noopener noreferrer">
                                    <img src={twitter} alt="Twitter" className={styles.img} />
                                </a>
                            </div>
                        </div>

                        <div className={`col-lg-3 col-sm-6 ${styles.contain}`}>
                            <p className={styles.head}>Services</p>
                            {servicesData.map((service, index) => (
                                <div key={index}>
                                    <div className={styles.text} onClick={() => toggleService(index)}>
                                        {service.name}
                                        <FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: '8px', cursor: 'pointer' }} />
                                    </div>
                                    {openService === index && (
                                        <div
                                            className={styles.text}
                                            style={{ cursor: 'pointer', color: service.color }} // Use the service color
                                            onClick={() => window.location.href = `tel:${service.number}`}
                                        >
                                            Call {service.number}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>

                        <div className={`col-lg-3 col-sm-6 ${styles.contain}`}>
                            <p className={styles.head}>Head Office</p>
                            <p className={styles.text}>Westlands, Nairobi, Kenya</p>
                            <p className={styles.text}>contact@meyani.com</p>
                            <p className={styles.text}>+254799041239</p>
                        </div>

                        <div className={`col-lg-3 col-sm-6 ${styles.contain}`}>
                            <p className={styles.head}>Opening Hours</p>
                            <p className={styles.text}>Mon - Fri : 08:00 - 18:00</p>
                            <p className={styles.text}>Sat : 10:00 - 16:00</p>
                            <p className={styles.text}>Support : 24/7</p>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: '#285a77', borderTop: 'solid 1px #707070' }}>
                <p className={styles.bottom}>Copyright © 2024 Meyani Tech. All rights reserved</p>
            </div>
        </>
    );
};

export default Footer;

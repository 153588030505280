import React, { useState } from 'react';
import './blog.css';
import { blogs } from './data'; // Assuming the blog data is in a separate data.js file
import Footer from "../Footer";

const Blog = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 3;

  // Calculate indices for the current page
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  // Handle pagination click
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="blog-container">
      {/* Hero Section */}
      <section className="hero-section">
        <h1>Meyani Tech Blog</h1>
        <p>Latest insights in technology, innovation, and sustainability.</p>
      </section>

      {/* Blog Section */}
      <div className="blog-list">
        {currentBlogs.map((blog) => (
          <div key={blog.id} className="blog-post">
            <img src={blog.image} alt={blog.title} className="blog-image" />
            <h2>{blog.title}</h2>
            <p>{blog.content.substring(0, 100)}... <a href="nothing.com">Read More</a></p>
            <div className="blog-info">
              <span>By {blog.author}</span> | <span>{blog.date}</span> | <span>{blog.category}</span>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination Section */}
      <div className="pagination">
        {Array.from({ length: Math.ceil(blogs.length / blogsPerPage) }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => paginate(index + 1)}
            className={currentPage === index + 1 ? 'active' : ''}
          >
            {index + 1}
          </button>
        ))}
      </div>

      {/* Footer Section */}
      <Footer /> {/* This will render the Footer component */}
    </div>
  );
};

export default Blog;
